<script setup lang="ts">
import Logo from '~/components/common/Logo.vue';
import Spacer from '~/components/common/Spacer.vue';

const router = useRouter();
const localePath = useLocalePath();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const headerConrainerRef = ref<HTMLDivElement | null>(null);

function onResize() {
  document.body.setAttribute(
    'style',
    `--menu-clearfix-height: ${headerConrainerRef.value!.clientHeight}px;`,
  );
}

function onClickLogout() {
  authStore.logout();

  router.push(localePath('/'));
}

onMounted(() => {
  window.addEventListener('resize', onResize);
  window.addEventListener('scroll', onResize);

  onResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
  window.removeEventListener('scroll', onResize);
});
</script>

<template>
  <nav class="header">
    <div ref="headerConrainerRef" class="header__container">
      <div class="container">
        <div class="logo">
          <NuxtLink :to="localePath('dashboard')">
            <Logo theme="light" :alt="$t('layout.external.navigation.logo-alt')" />
          </NuxtLink>
        </div>

        <Spacer />

        <div class="actions">
          <span>{{ user.Email }}</span>

          <Button class="button--inline-text" variant="text" theme="light" @click="onClickLogout">{{
            $t('layout.internal.navigation.profile.log-out')
          }}</Button>
        </div>
      </div>
    </div>

    <div class="header__clearfix"></div>
  </nav>
</template>

<style lang="scss" scoped>
.header__container {
  background-color: var(--white-darkened);
}

.container {
  position: relative;

  display: flex;
  align-items: stretch;

  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  .logo:not(img) {
    display: inline-flex;
    align-items: center;

    width: 7.5rem;

    a {
      display: inherit;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  font-size: var(--font-size-sm);

  .button {
    width: fit-content;

    font-size: inherit;
  }
}
</style>
